import "@fortawesome/fontawesome-free/js/all";

const inputs       = document.querySelectorAll("input");
const submitButton = document.querySelector(".login-btn");

inputs.forEach(input => input.addEventListener("input", disableButton));
submitButton.addEventListener("click", setLoadingState);

function setLoadingState(evt) {
	evt.preventDefault();

	this.disabled = true;
	this.classList.add("loading");
	this.form.submit();
}

function disableButton() {
	const isValid = this.validity.valid && this.value !== "";
	isValid ? submitButton.disabled = false : submitButton.disabled = true;
}
